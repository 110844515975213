<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="card-title pt-3 px-3 d-flex justify-content-between">
            <div class="breadcrumb-left">
              <h4>Faq category</h4>
              <div class="breadcrumb-sub-header">
                <router-link to="/dashboard">Dashboard </router-link>\ Faq
                category
              </div>
            </div>
            <div class="breadcrumb-right">
              <div class="card-toolbar">
                <v-btn
                  class="btn btn-primary"
                  @click="createFaqCategory()"
                  dark
                >
                  Add Faq category
                </v-btn>
              </div>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div
            class="search-wrapper border-outline mb-5 px-2 py-3 d-sm-flex justify-content-start"
          >
            <v-col cols="12" md="5">
              <v-text-field
                label="Title"
                v-model="search.title"
                v-on:keyup.enter="searchFaqCategory"
                @input="search.title = $event !== null ? $event : ''"
                clearable
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col class="d-flex" cols="12" md="5">
              <v-select
                :items="status"
                v-model="search.is_active"
                v-on:keyup.enter="searchFaqCategory"
                @input="search.is_active = $event !== null ? $event : ''"
                label="Status"
                item-text="name"
                item-value="value"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="2">
              <button
                class="btn btn-block btn-primary"
                @click="searchFaqCategory"
              >
                Search
              </button>
            </v-col>
          </div>

          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr class="text-left">
                  <th class="px-3">
                    <strong>Title</strong>
                  </th>
                  <th class="px-3"><strong>Status</strong></th>
                  <th class="pr-3 text-center"><strong>Action</strong></th>
                </tr>
              </thead>
              <template v-if="faqCategories.length > 0">
                <draggable
                  @change="sort"
                  @start="drag = true"
                  @end="drag = false"
                  tag="tbody"
                >
                  <tr v-for="(item, index) in faqCategories" :key="index">
                    <td class="px-2">
                      <a
                        @click="editFaqCategory(item)"
                        class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg"
                      >
                        <span class="ml-3">{{ item.title }}</span>
                      </a>
                    </td>
                    <td class="px-2">
                      <span
                        class="badge text-lg"
                        v-bind:class="{
                          'badge-success': item.is_active,
                          'badge-danger': !item.is_active
                        }"
                      >
                        {{ item.is_active ? "Active" : "In Active" }}
                      </span>
                    </td>

                    <td class="pr-0 px-2 text-center">
                      <template>
                        <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                          no-caret
                          right
                          no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a
                                @click="editFaqCategory(item)"
                                class="navi-link"
                              >
                                <span class="navi-icon">
                                  <v-icon color="blue darken-2"
                                    >fas fa-edit</v-icon
                                  >
                                </span>
                                <span class="navi-text">Edit Faq category</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a
                                @click.prevent="deleteFaqCategory(item.id)"
                                class="navi-link"
                              >
                                <span class="navi-icon">
                                  <v-icon color="red">fas fa-trash</v-icon>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                </draggable>
              </template>
              <template v-else>
                <tr>
                  <td class="text-center" colspan="5">
                    <h5>No Data Found</h5>
                  </td>
                </tr>
              </template>
            </table>
          </div>
        </div>

        <create-and-update
          ref="create-and-update"
          @refresh="getAllFaqCategories"
        ></create-and-update>
      </div>
    </div>
  </div>
</template>

<script>
import FaqCategoryService from "@/service/cms/faq/category/FaqCategoryService";
import CreateAndUpdate from "./CreateAndUpdate";
import draggable from "vuedraggable";

const faqCategory = new FaqCategoryService();
export default {
  name: "Index",
  components: {
    CreateAndUpdate,
    draggable
  },
  data() {
    return {
      drag: false,
      faqCategories: [],
      page: null,
      search: {
        name: "",
        is_active: ""
      },
      status: [
        { name: "Active", value: 1 },
        { name: "Inactive", value: 0 }
      ]
    };
  },
  mounted() {
    this.getAllFaqCategories();
  },

  methods: {
    searchFaqCategory() {
      this.getAllFaqCategories();
    },

    createFaqCategory() {
      this.$refs["create-and-update"].createFaqCategory();
    },

    editFaqCategory(faq) {
      this.$refs["create-and-update"].editFaqCategory(faq);
    },

    sort() {
      faqCategory.sort(this.faqs).then(response => {
        if (response.data.status === "OK") {
          this.$snotify.success("Sorted Successfully");
          this.getAllFaqs();
        } else {
          this.$snotify.error("Something Went Wrong");
        }
      });
    },

    deleteFaqCategory(id) {
      this.$confirm({
        message: `Are you Sure ??`,
        button: {
          yes: "Yes",
          no: "No"
        },
        callback: confirm => {
          if (confirm) {
            faqCategory.delete(id).then(response => {
              if (response.data.status == "OK") {
                this.$snotify.success("Delete Successfully");
                this.getAllFaqCategories();
              } else {
                this.$snotify.error("Something Went Wrong");
              }
            });
          }
        }
      });
    },

    getAllFaqCategories() {
      faqCategory
        .paginate(this.search, this.page)
        .then(response => {
          this.faqCategories = response.data.data;
        })
        .finally(() => {
          return false;
        });
    }
  }
};
</script>

<style scoped></style>
