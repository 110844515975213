<template>
  <v-row align="center">
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ edit ? "Edit" : "Add" }} Faq Category</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form lazy-validation autocomplete="off">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="faqCategory.title"
                    label="Title"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <label>Description</label>
                  <ckeditor
                    v-model="faqCategory.description"
                    :config="editorConfig"
                    value="faqCategory.description"
                  ></ckeditor>
                  <span
                    class="text-danger"
                    v-if="
                      !$v.faqCategory.description.required &&
                        $v.faqCategory.description.$error
                    "
                  >
                    Description is Required !!
                  </span>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-switch
                    v-model="faqCategory.is_active"
                    label="Status"
                    outlined
                    dense
                    required
                  ></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" dark @click="createOrUpdate">
            {{ edit ? "Update" : "Save" }}
          </v-btn>
          <v-btn color="red darken-1" dark @click="close"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import FaqCategoryService from "@/service/cms/faq/category/FaqCategoryService";

const faqCategoryServiceVar = new FaqCategoryService();
export default {
  name: "CreateAndUpdate",
  data() {
    return {
      dialog: false,
      edit: false,
      faqCategory: {
        title: "",
        description: "",
        is_active: true
      },
      editorConfig: {
        toolbar: [["Bold", "Italic", "Underline", "BulletedList"]],
        autoParagraph: false
      }
    };
  },
  validations: {
    faqCategory: {
      title: { required },
      description: { required }
    }
  },
  methods: {
    close() {
      this.dialog = false;
      this.reset();
    },

    createFaqCategory() {
      this.dialog = true;
      this.edit = false;
      this.reset();
    },

    updateFaqCategory(faq) {
      this.edit = true;
      this.dialog = true;
      this.reset();
      this.faq = faq;
    },
    createOrUpdate() {
      this.$v.faqCategory.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        if (!this.edit) {
          this.create();
        } else {
          this.update();
        }
      }
    },
    reset() {
      this.$v.$reset();
      this.faqCategory = {
        name: "",
        description: "",
        is_active: true
      };
    },
    create() {
      faqCategoryServiceVar.store(this.faqCategory).then(response => {
        if (response.data.status == "OK") {
          this.$snotify.success("Add faq category successfully");
          this.$emit("refresh");
          this.close();
        } else {
          this.$snotify.error("Something Went Wrong");
        }
      });
    },
    update() {
      faqCategoryServiceVar
        .update(this.faqCategory.id, this.faqCategory)
        .then(response => {
          if (response.data.status == "OK") {
            this.$snotify.success("Add faq category successfully");
            this.$emit("refresh");
            this.close();
          } else {
            this.$snotify.error("Something Went Wrong");
          }
        });
    }
  }
};
</script>

<style scoped></style>
